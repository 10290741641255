.containerScroll::-webkit-scrollbar {
  width: 8px; /* Tamaño del scroll en vertical */
  height: 8px; /* Tamaño del scroll en horizontal */
  padding-right: 4px;
  /*display: none;*/ /* Ocultar scroll */
}
/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.containerScroll::-webkit-scrollbar-thumb {
  background: #84b044;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.containerScroll::-webkit-scrollbar-thumb:hover {
  background: #71963a;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.containerScroll::-webkit-scrollbar-thumb:active {
  background-color: #71963a;
}
/* Ponemos un color de fondo y redondeamos las esquinas del track */
.containerScroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}
/* Cambiamos el fondo cuando esté en active o hover */
.containerScroll::-webkit-scrollbar-track:hover,
.containerScroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
